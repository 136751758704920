<template>
  <b-tooltip
    v-if="isCreateZoneDisabled"
    :target="target"
    triggers="focus"
  >
    <div class="pt-50 pb-50">
      <span>{{ $t('tooltipZoneCreate') }}</span>
      <b-button
        block
        size="sm"
        class="mt-50"
        variant="primary"
        @click="$router.push({ name: 'billing' })"
      >
        {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
      </b-button>
    </div>
  </b-tooltip>
</template>

<script>
import {
  BButton,
  BTooltip,
} from 'bootstrap-vue'
import mixinTooltipCreateZone from '@/mixins/tooltip/tooltip-create-zone'

export default {
  name: 'TooltipCreateZone',
  components: {
    BButton,
    BTooltip,
  },
  mixins: [
    mixinTooltipCreateZone,
  ],
  props: {
    target: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>
