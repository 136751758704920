<template>
  <zones-events
    :items="zones"
    :router-link-name="routerLinkName"
    route-name="zones"
    :history="history"
    :button-name="`${$t('buttonNameNewZone')}`"
    :is-creating="isCreating"
    @create="create"
  >
    <template v-slot:tooltip="{ target }">
      <tooltip-create-zone
        v-if="target"
        :target="target"
      />
    </template>
  </zones-events>
</template>

<script>
import ZonesEvents from '@/components/ZonesEvents.vue'
import { mapGetters, mapActions } from 'vuex'

import mixinTooltipCreateZone from '@/mixins/tooltip/tooltip-create-zone'
import TooltipCreateZone from '@/components/tooltip/TooltipCreateZone.vue'

export default {
  name: 'Zones',
  components: {
    ZonesEvents,
    TooltipCreateZone,
  },
  mixins: [
    mixinTooltipCreateZone,
  ],
  data() {
    return {
      isCreating: false,
      routerLinkName: 'zone',
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      navHistory: 'main/navHistory',
      zones: 'floorPlan/zones',
      isMenusFetched: 'menuManagement/isMenusFetched',
      isZonesFetched: 'floorPlan/isZonesFetched',
    }),
    history() {
      return this.navHistory.zone
        .map(i => this.zones.find(j => j.id === i))
        .filter(i => !!i)
    },
  },
  watch: {
    restaurant() {
      this.fetch()
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    ...mapActions({
      getMenus: 'menuManagement/getMenus',
      getZones: 'floorPlan/getZones',
      createZone: 'floorPlan/createZone',
      setZones: 'floorPlan/setZones',
    }),
    fetch() {
      if (!this.restaurant) {
        return
      }

      let getActions = []
      if (!this.isMenusFetched) {
        getActions = [...getActions, 'getMenus']
      }
      if (!this.isZonesFetched) {
        getActions = [...getActions, 'getZones']
      }

      getActions.map(getAction => this[getAction]())
    },
    async create() {
      if (this.isCreateZoneDisabled) {
        return
      }

      this.isCreating = true

      const zone = await this.createZone(this.$t('New Zone'))

      // eslint-disable-next-line no-undef
      this.setZones([...this.zones, structuredClone(zone)])

      await this.$router.push({
        name: this.routerLinkName,
        params: {
          id: zone.id,
          isFocusOnName: true,
        },
      })

      this.isCreating = false
    },
  },
}
</script>
